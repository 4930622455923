<template>
  <div class="aftersale">
    <div class="tabbers">
      <!-- <div class="tabbertop">商品信息</div> -->
      <div class="tab" v-if="ifactive != 100">
        <el-table :data="oderItem" style="width: 100%">
          <el-table-column label="商品">
            <template slot-scope="scope">
              <div class="goodslist">
                <img class="goodsimg" :src="scope.row.image" alt="" />
                <div class="goodsright">
                  <div class="gootitle">
                    {{ scope.row.goodsName }}
                  </div>
                  <div class="skulsi">
                    {{ scope.row.simpleSpecs }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="skuId" label="商品编号" width="180">
          </el-table-column>
          <el-table-column prop="goodsPrice" label="单价" width="90">
             <template slot-scope="scope">
              <span v-if="scope.row.promotionType == 'POINTS_GOODS'">{{ scope.row.point || 0 }}积分</span>
              <span v-else>￥{{ scope.row.unitPrice || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量" width="90">
          </el-table-column>
        </el-table>
      </div>
      <div class="tab" v-if="ifactive == 100">
        <el-table :data="afterList" style="width: 100%">
          <el-table-column label="商品">
            <template slot-scope="scope">
              <div class="goodslist">
                <img class="goodsimg" :src="scope.row.goodsImage" alt="" />
                <div class="goodsright">
                  <div class="gootitle">
                    {{ scope.row.goodsName }}
                  </div>
                  <div class="skulsi">
                    {{ scope.row.simpleSpecs }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="goodsId" label="商品编号" width="180">
          </el-table-column>
          <el-table-column prop="unitPrice" label="单价" width="90">
          </el-table-column>
          <el-table-column prop="num" label="数量" width="90">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="ifactive == 100">
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">售后状态：</div>
        <div class="nums">
          <span v-if="afterList[0].serviceStatus == 'APPLY'">申请中</span>
          <span v-if="afterList[0].serviceStatus == 'PASS'">已通过</span>
          <span v-if="afterList[0].serviceStatus == 'REFUSE'">已拒绝</span>
          <span v-if="afterList[0].serviceStatus == 'BUYER_RETURN'">已退货，待卖家收货</span>
          <span v-if="afterList[0].serviceStatus == 'COMPLETE'">完成售后</span>
          <span v-if="afterList[0].serviceStatus == 'BUYER_CANCEL'">取消售后</span>
        </div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">售后订单号：</div>
        <div class="nums">{{ afterList[0].sn }}</div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">订单号：</div>
        <div class="nums">{{ afterList[0].orderSn }}</div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">创建时间：</div>
        <div class="nums">{{ afterList[0].createTime || '/' }}</div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">售后类型：</div>
        <div class="nums">
          <span v-if="afterList[0].serviceType == 'RETURN_GOODS'">退货退款</span>
          <span v-if="afterList[0].serviceType == 'RETURN_MONEY'">仅退款</span>
        </div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">申请数量：</div>
        <div class="nums">{{ afterList[0].num || 0 }}</div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">申请原因：</div>
        <div class="nums">{{ afterList[0].reason || '/' }}</div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">问题描述：</div>
        <div class="nums">{{ afterList[0].problemDesc }}</div>
      </div>
      <div class="tuihuacar">
        <div class="name">图片信息：</div>
        <div class="nums"  style="display: flex; align-items: center" v-if="afterList[0].afterSaleImage.length > 0">
          <img style=" width: 60px;height: 60px;margin-left: 5px;" v-for="(itms,index) in afterList[0].afterSaleImage" :key="index" :src="itms" alt="">
        </div>
         <div class="nums"  style="display: flex; align-items: center" v-else>
          /
        </div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">退款方式：</div>
        <div class="nums">
          原路返回
          <!-- {{ afterList[0].refundWay }} -->
          </div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">申请退款金额：</div>
        <div class="nums">{{ afterList[0].applyRefundPrice || 0 }}</div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">实际退款金额：</div>
        <div class="nums">{{ afterList[0].actualRefundPrice ||  0 }}</div>
      </div>
       <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">退款时间：</div>
        <div class="nums">{{ afterList[0].refundTime || '/' }}</div>
      </div>
    </div>
    <div v-if="ifactive != 100">
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">售后类别：</div>
        <div
          :class="active == index ? 'lsitaa lsitaatwo' : 'lsitaa'"
          v-for="(item, index) in lsita"
          :key="index"
          @click="tocards(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">提交数量：</div>
        <div class="nums">{{ oderItem[0].num }}</div>
      </div>
      <div class="tuihuacar" style="display: flex; align-items: center">
        <div class="name">*申请原因：</div>
        <div class="nums">
          <el-input v-model="inputbecos" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <div class="tuihuacar">
        <div class="name">*问题描述：</div>
        <div class="nums">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <div class="tuihuacar">
        <div class="name">图片信息：</div>
        <div class="nums">
          <el-upload
            :action="uploadUrl"
            class="upload-demo"
            ref="upload"
            name="file"
            :multiple="true"
            :headers="headers"
            :show-file-list="false"
            :auto-upload="true"
            :limit="9"
            :on-success="uploadFile"
            :before-upload="beforeAvatarUpload"
          >
            <div class="tupains">
              <div class="uplids">
                <img class="imgs" src="../../assets/img/pic.png" alt="" />
                <div>上传图片</div>
              </div>
              <span class="spansaa" v-if="imgList.leng < 1"
                >最多只能上传9张图</span
              >
              <img
                class="imgsaa"
                v-for="(im, idx) in imgList"
                :key="idx"
                :src="im"
                alt=""
              />
            </div>
          </el-upload>
        </div>
      </div>
      <div class="tuihuacar">
        <div class="name">退还方式：</div>
        <div class="nums">原路返回</div>
      </div>
    </div>
    <div class="btns" v-if="ifactive != 100" @click="addforme">提交申请</div>
  </div>
</template>

<script>
import {
  oderListdetail,
  postsaveafterSale,
  getafterSaleSnfterSaleSn,
} from "../../request/homeApi";
import { oupdet } from "@/request/xie";
export default {
  data() {
    return {
      inputbecos: "",
      textarea: "",
      imgList: [],
      uploadUrl: "",
      lsita: [
        {
          name: "退货退款",
        },
        {
          name: "仅退款",
        },
      ],
      oderItem: [],
      afterList: [],
      orderSn: "",
      headers: {},
      active: 0,
      goodsid: "",
      ifactive: 0,
      num:"",
    };
  },
  mounted() {
    this.orderSn = this.$route.query.orderSn;
    this.goodsid = this.$route.query.ids;
    if (this.$route.query.active == 100) {
      this.ifactive = this.$route.query.active;
      this.getafterSaleSnfterSaleSnApi();
    } else {
      this.oderdetailApi();
      this.uploadUrl = oupdet();
      let token = localStorage.getItem("token");
      this.headers = {
        accessToken: token,
      };
    }
  },
  methods: {
    // 获取售后详情
    getafterSaleSnfterSaleSnApi() {
      getafterSaleSnfterSaleSn(this.orderSn)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if(res.result.afterSaleImage){
              res.result.afterSaleImage = res.result.afterSaleImage.split(',')
            }
            this.afterList.push(res.result);
            console.log(this.afterList)
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    //   提交售后
    addforme() {
      let data = {
        orderItemSn: this.oderItem[0].sn,
        serviceType:
          this.active == 0
            ? "RETURN_GOODS"
            : this.active == 1
            ? "RETURN_MONEY"
            : "",
        reason: this.inputbecos,
        problemDesc: this.textarea,
        num: this.oderItem[0].num,
        images: this.imgList.join(","),
        goodsId: this.goodsid,
        skuId: this.oderItem[0].skuId,
      };
      postsaveafterSale(data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$router.push({
              path: "/mepages/aftersaleOder?active=5&page=0&chind=2",
            });
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    //   选择类型
    tocards(index) {
      this.active = index;
    },
    beforeAvatarUpload() {},
    uploadFile(param) {
      this.imgList.push(param.result);
    },
    // 获取订单详情
    oderdetailApi() {
      oderListdetail(this.orderSn)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            res.result.orderItems.forEach((element) => {
              if (element.goodsId == this.goodsid) {
                this.oderItem.push(element);
              }
            });
            console.log(this.oderItem);
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.aftersale {
  background: #ffffff;
  padding: 35px;
  margin-bottom: 80px;
}
.tabbers {
  width: 100%;
  margin-top: 17px;
  border: 1px solid #dcdcdc;
  .tabbertop {
    width: 100%;
    height: 36px;
    background: #efefef;
    border-bottom: 1px solid #dcdcdc;
    padding: 0 15px;
    line-height: 36px;
    font-size: 14px;
    color: #535353;
  }
}
.goodslist {
  display: flex;
  align-items: center;
  .goodsimg {
    width: 130px;
    height: 72px;
  }
  .goodsright {
    width: 250px;
    height: 72px;
    margin-left: 10px;
    .gootitle {
      width: 100%;
      font-size: 14px;
      color: #535353;
    }
    .skulsi {
      font-size: 14px;
      color: #818181;
    }
  }
}
.tupains {
  display: flex;
  justify-content: flex-start;
  justify-items: self-end;
  color: #818181;
  font-size: 12px;
  .imgsaa {
    width: 60px;
    height: 60px;
    margin-left: 5px;
  }
  .spansaa {
    margin-top: 40px;
    margin-left: 10px;
  }
  .uplids {
    width: 60px;
    height: 60px;
    background: #feffff;
    border: 1px solid #dcdcdc;
    text-align: center;
    color: #818181;
    font-size: 12px;
    .imgs {
      width: 20px;
      height: 17px;
      margin: 10px 0 10px;
    }
  }
}
.tuihuacar {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  font-size: 14px;
  color: #535353;
  .name {
    font-size: 14px;
    color: #535353;
  }
  .lsitaa {
    width: 75px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    // border-radius: 0px 2px 2px 0px;
  }
  .lsitaatwo {
    background: #ffffff;
    color: #00a3e0;
    border: 1px solid #00a3e0;
  }
}
.btns {
  width: 158px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #00a3e0;
  border-radius: 2px;
  font-size: 16px;
  color: #ffffff;
  margin-top: 70px;
  margin-bottom: 200px;
}
</style>